@font-face {
  font-family: "Eina";
  src: url("./fonts/eina_01_regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Eina-semibold";
  src: url("./fonts/eina_01_semibold-webfont.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Mediator-bold";
  src: url("./fonts/MediatorSerifWeb-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Mediator";
  src: url("./fonts/MediatorSerifWeb-LightItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Exceptional";
  src: url("./fonts/ExceptionalAlt-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: "Eina", sans-serif;
  line-height: 1.25;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
